<template>
    <b-overlay :show="showLoader">
        <b-card>
            <h2 style="font-weight: bold;">Izpolnite podatke o nepremičnini</h2>
            <validation-observer ref="validate">
                <b-form class="form">
                    <RealEstate v-if="object" ref="realEstate" v-model="object" type="edit"/>
                    <b-form-group class="text-center text-sm-right">
                        <b-button variant="secondary" @click.prevent="validationForm">UREDI NEPREMIČNINO</b-button>
                    </b-form-group>
                </b-form>
            </validation-observer>
        </b-card>
    </b-overlay>
</template>

<script>
    import { ValidationObserver } from 'vee-validate'
    import RealEstate from '@/views/Components/RealEstate'
    import {BOverlay, BCard, BForm, BFormGroup, BButton} from 'bootstrap-vue'
    export default {
        components: {
            ValidationObserver,
            RealEstate,
            BOverlay,
            BCard,
            BForm,
            BFormGroup,
            BButton
        },
        data() {
            return {
                object: null,
                showLoader: false
            }
        },
        methods: {
            getOptionToDisplay(agent) {
                if (!agent) return 'Napaka pri prikazu'
                return `${agent.name} ${agent.surname} - (${agent.email}, ${agent.telephone_number})`
            },
            validationForm() {
                this.$refs.validate.validate().then(success => {
                    if (success) {
                        this.editRealEstate()
                    }
                })
            },
            async getRealEstate() {
                this.showLoader = true
                try {
                    const loadPromise = await this.$http.get(`/api/management/v1/real_estate/${this.$route.params.real_estate_id}`)
                    this.object = loadPromise.data
                } catch (error) {
                    this.$printError(error.response.data)
                } finally {
                    this.showLoader = false
                }
            },
            parseAllNumericalProperties() {
                this.object.price = parseFloat(parseFloat(this.object.price).toFixed(2))
                this.object.listing_type = parseFloat(this.object.listing_type)
                this.object.size = parseFloat(parseFloat(this.object.size).toFixed(2))
                this.object.build = parseFloat(this.object.build)
                this.object.renovated = parseFloat(this.object.renovated)
            },
            async editRealEstate() {
                this.showLoader = true

                try {
                    const isImageUploadSuccessfull = await this.$refs.realEstate.$refs.imageSelector.UploadImagesToAPI()
                    if (!isImageUploadSuccessfull) {
                        this.$printError('Prišlo je do napake pri nalaganju slik')
                        return
                    }
                    if (this.object.main_photo === '') {
                        this.$printWarning('Potrebno je dodati vsaj eno fotografijo')
                        return
                    }

                    this.object.author_id = this.$store.state.user.userData.id
                    this.object.date_posted = new Date()
                    this.parseAllNumericalProperties()

                    await this.$http.patch(`/api/management/v1/real_estate/${this.$route.params.real_estate_id}`, this.object)
                    this.$printSuccess('Nepremičnina je urejena')
                    this.dataLoaded = true
                    setTimeout(() => {
                        this.$router.push({name: 'admin-real-estates-list'})
                    }, 1000)
                  
                } catch (error) {
                    this.$printError(error.response.data)
                } finally {
                    this.showLoader = false
                }
            }
        },
        async mounted() {
            await this.getRealEstate()
        }
    }
</script>